exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-pay-fail-tsx": () => import("./../../../src/pages/pay/fail.tsx" /* webpackChunkName: "component---src-pages-pay-fail-tsx" */),
  "component---src-pages-pay-success-tsx": () => import("./../../../src/pages/pay/success.tsx" /* webpackChunkName: "component---src-pages-pay-success-tsx" */),
  "component---src-pages-pp-tsx": () => import("./../../../src/pages/pp.tsx" /* webpackChunkName: "component---src-pages-pp-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-single-tsx": () => import("./../../../src/templates/single.tsx" /* webpackChunkName: "component---src-templates-single-tsx" */)
}

